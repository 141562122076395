window.require = require
window.jQuery = window.$ = require('jquery')
const recaptchaSetup = require('webhandle-emailer/client-js/make-form-recaptcha')


var tri = require('tripartite')
var tripartite = tri

if (window.recaptchaId && document.querySelectorAll('form.google-recaptcha-form').length > 0) {
	recaptchaSetup(window.recaptchaId)
}

/*
const SwipeListener = require('swipe-listener')
const Shiner = require('shiner/shiner-no-jquery')($)
window.Shiner = Shiner
let createShows = require('shiner/create-shows')
*/

/*
	createShows(Shiner, SwipeListener ).forEach(shine => shine.shineOn())
*/
/*
	require('./enable-remote-logging')
*/

/*
let tribar = document.querySelector('header .tribar')
if(tribar) {
	tribar.addEventListener('click', function (evt) {
		evt.preventDefault()
		document.querySelector('header nav').classList.toggle('open')
		document.querySelector('body').classList.toggle('locked');
	})
}
*/




$(function() {
	$('header .tribar').on('click', function(evt) {
		evt.preventDefault()
		$('header nav').toggleClass('open')
		$('body').toggleClass('locked');
	})
	$('header .menu li').removeClass('current')
	$('header .menu a').each(function() {
		if($(this).attr('href') == window.location.pathname) {
			$(this).closest('li').addClass('current')
		}
	})
})



var showProperty = require('./templates/property-popup.tri')
var showSlide = require('./templates/property-popup-slide.tri')


require('shiner')

var numSlides = $('.slide-banner .slide').length

for(var i = 0; i < numSlides; i++) {
	$('.dots').append('<div class="dot">&nbsp;</div><br/>')
}


var slideshow = $('.slide-banner .slide').shiner()
slideshow.onVisible = function(slide, index) {
	$('.dots .dot').removeClass('current').eq(index).addClass('current')
}

$('.indicators .down').click(function(evt) {
	evt.preventDefault()
	slideshow.next()
})

$('.indicators .up').click(function(evt) {
	evt.preventDefault()
	slideshow.previous()
})

setTimeout(function() {

	slideshow.next()
	slideshow.previous()
})


$('.menu-holder').click(function(evt) {
	evt.preventDefault()
	$('body').toggleClass('menu-open')
})

$('.projects .filter a').click(function(evt) {
	evt.preventDefault()
	$('.projects .filter a').removeClass('current')
	$(this).addClass('current')
	if($(this).hasClass('all')) {
		$('.projects .project-row a').addClass('show')
	}
	else {
		var type = $(this).attr('data-project-type')
		$('.projects .project-row a').removeClass('show')
		$('.projects .project-row a[data-project-type=' + type + ']').addClass('show')
	}
})

$('.projects .project').click(function(evt) {
	evt.preventDefault()
	var projectId = $(this).attr('data-id')
	
	$.get('/project-info/' + projectId, function(data) {
		$('.projects .property-popup').remove()
		$('.projects').append(showProperty(data))
		var galImgNum = data.galleryImages.length
		for(var galNum = 0; galNum < galImgNum; galNum++) {
			$('.property-popup .slide-area').append(showSlide(data.galleryImages[galNum]))
		}
		
		var propSlideshow = $('.property-popup .slide').shiner()
		$('.property-popup .next').click(function(evt) {
			evt.preventDefault();
			propSlideshow.next()
		})
		$('.property-popup .prev').click(function(evt) {
			evt.preventDefault();
			propSlideshow.previous()
		})
		propSlideshow.onVisible = function(slide, slideIndex) {
			var img = $(slide).get(0).style.backgroundImage
			
			if(img.indexOf('.mp4') > -1) {
				var vid = img.substring(5, img.length - 2)
				setTimeout(function() {
					propSlideshow.stop()
					$(slide).append('<video ><source src="' + vid + '" type="video/mp4"></video>')
					$(slide).css('background-color', 'rgba(0, 0, 0, 0.7)')
					var vidPlayer = $(slide).find('video').get(0)
					vidPlayer.play()
					vidPlayer.addEventListener('ended', function() {
						propSlideshow.next()
						$(slide).html('')
					})
				})
			}
		}
		propSlideshow.onInvisible = function(slide, slideIndex) {
			$(slide).html('')
		}
		setTimeout(function() {
			propSlideshow.next()
		})
		
		if($('.property-popup').css('position') == 'relative') {
			$('html, body').animate({
				scrollTop: $(".property-popup").offset().top
			}, 1000)
		}
	})
})

$('.projects').on('click', '.close', function(evt) {
	evt.preventDefault()
	$('.projects .property-popup').remove()
})

$('.press-grid .thing').click(function() {
	if($(this).attr('data-href')) {
		window.location = $(this).attr('data-href')
	}
})

$('.menu a').click(function() {
	$('body').removeClass('menu-open')
})

$('.menu a[href="' + window.location.pathname + '"]').addClass('current')

function doProjectOffset() {
	var off = parseInt($('.project-show').attr('data-offset'))
	var px = ($('.project-show .project').eq(0).width() + 40) * off
	$('.project-show .project').eq(0).css('margin-left', -1 * px)
}

$('.project-show .right').click(function() {
	var num = parseInt($('.project-show').attr('data-offset'))
	if(num < ($('.project-show .project').length - 1)) {
		$('.project-show').attr('data-offset', num + 1)
		doProjectOffset()
	}
})

$('.project-show .left').click(function() {
	var num = parseInt($('.project-show').attr('data-offset'))
	if(num > 0) {
		$('.project-show').attr('data-offset', num - 1)
		doProjectOffset()
	}
})

$('body').on('click', '.hide-info', function() {
	$(this).closest('.property-popup').addClass('rollup')
})

$('body').on('click', '.show-info', function() {
	$(this).closest('.property-popup').removeClass('rollup')
})

$('.expando .opener').click(function(evt) {
	evt.preventDefault()
	$(this).closest('.expando').toggleClass('open')
})
